<p class="text-center">Assignment: {{ assignmentId }}</p>
<div class="d-flex flex-column gap-3">
  <app-page-divider
    [text]="'assignments.information.title' | transloco"
  ></app-page-divider>
  <app-assignment-information
    [assignmentId]="assignmentId"
  ></app-assignment-information>
  <app-page-divider
    [text]="'assignments.courseDates.title' | transloco"
  ></app-page-divider>
  <app-assignment-course-dates></app-assignment-course-dates>
  <app-page-divider
    [text]="'assignments.participants.title' | transloco"
  ></app-page-divider>
  <app-assignment-participant
    [assignmentId]="assignmentId"
  ></app-assignment-participant>
  <app-page-divider
    [text]="'assignments.communication.title' | transloco"
  ></app-page-divider>
  <app-assignment-communication
    [assignmentId]="assignmentId"
  ></app-assignment-communication>
  <app-page-divider
    [text]="'assignments.attendance.title' | transloco"
  ></app-page-divider>
  <app-assignment-attendance></app-assignment-attendance>
  <app-page-divider
    [text]="'assignments.skills.title' | transloco"
  ></app-page-divider>
  <app-assignment-sportskills></app-assignment-sportskills>
</div>
