import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentContactParticipantsModalComponent } from '../assignment-participants-contacting-modal/assignment-participants-contacting-modal.component';

@Component({
  selector: 'app-assignment-contact-participants',
  templateUrl: './assignment-contact-participants.component.html',
  styleUrl: './assignment-contact-participants.component.scss'
})
export class AssignmentContactParticipantsComponent {
  @Input() assignmentId!: number;

  constructor(private bsModalService: BsModalService) { }

  public openModal(): void {
    this.bsModalService.show(AssignmentContactParticipantsModalComponent, {
      initialState: {
        assignmentId: this.assignmentId
      }
    });
  }
}
