import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsCommunicationStateService } from '../../services/assignments-communication-state-service';
import { AssignmentsCommunicationService } from '../../services/assignments-communication.service';
import { AssignmentCommunicationNotesPostModel } from '../../models/assignment-communication-note-post.model';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-assignment-communication-notes-modal',
  templateUrl: './assignment-communication-notes-modal.component.html',
  styleUrl: './assignment-communication-notes-modal.component.scss'
})
export class AssignmentCommunicationNotesModalComponent implements OnInit {
  errorMessage: string | null = null;
  isLoading: boolean = true;
  assignmentId!: number;
  noteForm?: FormGroup;
  assignmentCommunicationNoteModel!: AssignmentCommunicationNotesPostModel;

  constructor(
    private assignmentCommunicationStateService: AssignmentsCommunicationStateService,
    private assignmentCommunicationService: AssignmentsCommunicationService,
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.assignmentCommunicationService.getAssignmentCommunication(this.assignmentId)
      .subscribe({
        next: (communicationModel) => {
          this.isLoading = false
          this.initForm(communicationModel.note);
        }
      });
  }

  public saveForm(): void {
    if (this.noteForm?.valid) {
      this.sendAssignmentCommunicationNotes();
    }
  }

  private sendAssignmentCommunicationNotes(): void {
    this.errorMessage = null;
    this.isLoading = true;

    this.assignmentCommunicationNoteModel = { note: this.noteForm?.value.note };
    this.assignmentCommunicationService.sendAssignmentCommunicationNote(this.assignmentId, this.assignmentCommunicationNoteModel)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isSuccess) {
            this.assignmentCommunicationStateService.loadAssignmentCommunication(this.assignmentId);
            this.bsModalService.hide();
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage = this.translocoService.translate('assignments.communication.modalError');
        }
      });
  }

  private initForm(value: string): void {
    this.noteForm = this.formBuilder.group({
      note: [value]
    });
  }
}
