import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { CourseDetailsStateService } from './services/course-details-state.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assigment-details',
  templateUrl: './course-details.component.html',
  styleUrl: './course-details.component.scss'
})
export class AssigmentDetailsComponent implements OnInit {
  assignmentId!: number;

  constructor(
    private route: ActivatedRoute,
    private headerStateService: HeaderStateService,
    private coursetDetailsStateService: CourseDetailsStateService,

  ) {
    this.headerStateService.setDetailHeader("Einsatzdetails", "Mehr infos zum Einsatz", '/assignments/my', 'fa-solid fa-arrow-up-right-from-square text-primary');
  }

  ngOnInit(): void {
    this.registerEvents();
    this.route.params.subscribe(params => {
      this.assignmentId = params['id'];
      this.coursetDetailsStateService.loadCourseDetails(this.assignmentId);
    });
  }

  // Header icon action
  private registerEvents(): void {
    this.coursetDetailsStateService.courseDetailsSubject.subscribe({
      next: (courseDetails) => {
        this.registerLinkClickEvent(''); // Remove for Demo?
        if (courseDetails == null) return;
        this.registerLinkClickEvent(courseDetails.informationLink);
      }
    })

  }

  private registerLinkClickEvent(link: string) {
    this.headerStateService.getActionClickedEvent().subscribe((x) => {
      // Navigate to link logic
      window.open(link, '_blank');
    })
  }
}
