<pmt-action-modal
  [title]="'assignments.communication.editUpdate' | transloco"
  submitButtonIconCode="''"
  (submitted)="saveForm()"
>
  <!-- Loading -->
  <ng-container *ngIf="!updateForm">
    <div class="text-center">
      <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
  </ng-container>

  <!-- Loading Finished -->
  <ng-container *ngIf="updateForm">
    <form [formGroup]="updateForm">
      <textarea
        class="form-control"
        formControlName="update"
        rows="3"
        [disabled]="isLoading"
      ></textarea>
    </form>
  </ng-container>
</pmt-action-modal>
