import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../services/assignment-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';

@Component({
    templateUrl: './my-assignments.component.html',
    styleUrl: './my-assignments.component.scss',
})
export class MyAssignmentsComponent implements OnInit {
    assignmentEventType = AssignmentEventType.RouteToDetails;
    modalRef?: BsModalRef;
    constructor(
        public assignmentsStateService: AssignmentStateService,
        private headerStateService: HeaderStateService,
        private bsModalService: BsModalService

    ) {
        this.headerStateService.setDetailHeader(
            'Meine Einsätze',
            'Übersicht deiner Einsätze',
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        this.assignmentsStateService.loadUpcomingAssignments();
        this.registerEvents();
    }

    // Opening the FilterModal
    public openModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.upcomingAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    // Opening FilterModal in the Header
    private registerEvents(): void {
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openModal();
        })
    }
}
