<div class="d-flex flex-column">
  <div>
    <label class="fw-bold">Anlass Nr.</label>
    <p>SKRD-343534</p>
  </div>
  <div>
    <label class="fw-bold">Titel</label>
    <p>
      Really long text that should wrap and bend but lets see whats hapening
      here
    </p>
  </div>
  <div>
    <label class="fw-bold">Sportart | Untersportart</label>
    <p>
      Really long text that should wrap and bend but lets see whats hapening
      here2
    </p>
  </div>
</div>
