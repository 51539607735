<pmt-action-modal
  [title]="'assignments.communication.feedback' | transloco"
  submitButtonIconCode="''"
  [submitButtonText]="'modal.sendButton' | transloco"
  (submitted)="saveForm()"
>
  <form [formGroup]="feedbackForm">
    <textarea formControlName="feedback" class="form-control" rows="3"></textarea>
  </form>
</pmt-action-modal>
