<app-page-divider
  [text]="'assignments.myAssignments.filter' | transloco"
></app-page-divider>
<app-filter-display
  [filterModelSubject]="assignmentsStateService.upcomingAssignmentsFilterSubject"
></app-filter-display>
<app-page-divider
  [text]="'assignments.myAssignments.list' | transloco"
></app-page-divider>
<app-assignments-list
  [assignmentSubject]="assignmentsStateService.upcomingAssignmentsSubject"
  [eventType]="assignmentEventType"
></app-assignments-list>
