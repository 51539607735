<pmt-action-modal
  [title]="'assignments.communication.editNotes' | transloco"
  submitButtonIconCode="''"
  (submitted)="saveForm()"
>
  <!-- Loading -->
  <ng-container *ngIf="!noteForm">
    <div class="text-center">
      <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
  </ng-container>

  <!-- Loading Finished -->
  <ng-container *ngIf="noteForm"
    ><form [formGroup]="noteForm">
      <textarea
        class="form-control"
        formControlName="note"
        rows="3"
        [disabled]="isLoading"
      ></textarea></form
  ></ng-container>
</pmt-action-modal>
