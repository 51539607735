import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-filter-display-item',
  templateUrl: './filter-display-item.component.html',
  styleUrl: './filter-display-item.component.scss'
})
export class FilterDisplayItemComponent implements OnInit {
  @Input() items!: BehaviorSubject<string[] | null>;
  @Input() maxVisibleItems: number = 2;
  visibleText?: string | null;
  overflowCount: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.registerEvents();
  }

  private registerEvents(): void {
    this.items.subscribe({
      next: items => {
        this.updateVisibleItems(items);
      },
    })
  }

  private updateVisibleItems(items: string[] | null): void {
    // We use the | null operation so a existing value in items can be set to null again
    // If the list is null there is no overflow or visible text
    if (items === null) {
      this.visibleText = null;
      this.overflowCount = 0;
      return;
    }
    let maxVisibleItems = this.maxVisibleItems;
    if (items.length < maxVisibleItems) maxVisibleItems = items.length;

    items.slice(0, maxVisibleItems);
    this.overflowCount = items.length - maxVisibleItems;
    this.visibleText = items.join(', ');
  }

}
