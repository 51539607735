import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { TrainerModel } from '../../trainer/models/trainer.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssignmentSubstituteModel } from '../models/assignments-substitute.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrainerService } from '../../trainer/services/trainer.service';
import { AssignmentsService } from '../services/assignments.service';
import { AssignmentChangeSubstituteResultModel } from '../models/assignment-change-substitute-result.model';

@Component({
  selector: 'app-exchange-assignments-modal',
  templateUrl: './exchange-assignments-modal.component.html',
  styleUrl: './exchange-assignments-modal.component.scss'
})
export class ExchangeAssignmentsModalComponent implements OnInit {
  @Output() onSuccess = new EventEmitter<boolean>();
  isCloseOnly: boolean = false;
  selectedAssignments: AssignmentsOverviewModel[] = [];
  trainers$!: Observable<TrainerModel[]>;
  selectedTrainer?: TrainerModel;
  exchangeAssignmentsForm!: FormGroup;

  private subsituteAssignments?: AssignmentSubstituteModel;
  private trainerSubject = new BehaviorSubject<TrainerModel[]>([]);

  constructor(
    private trainerService: TrainerService,
    private formBuilder: FormBuilder,
    private assignmentService: AssignmentsService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadInitialDate();
  }

  public sendSubsituteAssignments(substituteAssignments: AssignmentSubstituteModel): void {
    if (!this.exchangeAssignmentsForm?.valid) return;
    this.assignmentService.sendSubstituteAssignments(substituteAssignments).subscribe(
      (response: AssignmentChangeSubstituteResultModel[]) => {
        response.forEach((resultModel) => {
          const matchingAssignment = this.selectedAssignments.
            find((assignment) => assignment.id == resultModel.assignmentId);
          if (matchingAssignment) {
            matchingAssignment.isSuccess = resultModel.isSuccessful;
            matchingAssignment.errorMessage = resultModel.errorMessage;
          }
        });
        this.onSuccess.emit(true);
      }
    )
    this.isCloseOnly = true;
  }

  protected setSubstituteModel(): void {
    this.subsituteAssignments = this.createAssignmentsSubstituteModel();
    this.sendSubsituteAssignments(this.subsituteAssignments);
  }

  protected isAssignmentOnSameDay(from: Date | undefined, to: Date | undefined): boolean {
    if (!from || !to) return false;
    return new Date(from).toDateString() === new Date(to).toDateString();
  }

  private initForm(): void {
    this.exchangeAssignmentsForm = this.formBuilder.group({
      trainerSelect: [null, Validators.required]
    })
    this.exchangeAssignmentsForm.markAllAsTouched();
  }

  private loadInitialDate(): void {
    if (this.selectedAssignments.length > 0 && this.selectedAssignments[0].sportId) {
      this.loadTrainers(this.selectedAssignments[0].sportId);
    }
    this.trainers$ = this.trainerSubject;
  }

  private loadTrainers(sportId: number) {
    this.trainerService.getTrainers(sportId).subscribe({
      next: trainers => {
        const trainersWithVakant = [this.createVacancyTrainer(), ...trainers];
        this.trainerSubject.next(trainersWithVakant);
      }
    })
  }

  private createAssignmentsSubstituteModel(): AssignmentSubstituteModel {
    return {
      trainerId: this.selectedTrainer?.id,
      assignmentIds: this.selectedAssignments
        .map(assignment => assignment.id)
        .filter((id) => id !== undefined)
    };
  }

  private createVacancyTrainer(): TrainerModel {
    const vacantTrainer = new TrainerModel();
    vacantTrainer.id = 0;
    vacantTrainer.firstName = "Vacant";
    vacantTrainer.lastName = "";

    return vacantTrainer;
  }
}
