<!-- Header Row -->
<div class="d-flex align-items-center p-1 gap-1">
  <!-- Date column with fixed width -->
  <div class="date-column-width">
    <label class="fw-bold">Datum</label>
  </div>
  <!-- Flexible space for location -->
  <div class="flex-grow-1">
    <!-- ToDo: transloco -->
    <label class="fw-bold">Anlage | Raum</label>
  </div>
</div>
<!-- Data Rows -->
<div *ngFor="let courseData of mock; let i = index" class="align-items-start p-1">
  <div class="d-flex flex-row gap-1">
    <div class="d-flex flex-column date-column-width">
      <!-- If End Date exists -->
      <ng-container *ngIf="
          courseData.endDate && courseData.endDate != courseData.startDate;
          else singleDateTime">
        <!-- Start Date and Start Time -->
        <div class="d-flex flex-row gap-1">
          <div>{{ courseData.startDate }}</div>
          {{ courseData.startTime }}
        </div>
        <div>
          {{ courseData.endDate }}
          {{ courseData.endTime }}
        </div>
      </ng-container>
      <!-- Fallback for no End Date -->
      <ng-template class="align-items-center" #singleDateTime>
        <div class="d-flex flex-row gap-1">
          <p>{{ courseData.startDate }}</p>
          <p>{{ courseData.startTime }} - {{ courseData.endTime }}</p>
        </div>
      </ng-template>
    </div>
    <!-- Location -->
    <div class="flex-grow-1">
      {{ courseData.location }}
    </div>
    <!-- Cancel button -->
    <div class="d-flex align-items-center">
      <!-- ToDo: transloco -->
      <button type="button" data-bs-toggle="tooltip" title="Durchführung absagen" class="btn btn-default btn-sm">
        <i class="fa-solid fa-ban"></i>
      </button>
    </div>
  </div>
  <hr class="light-divider p-0 m-1">
</div>