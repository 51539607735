import { Component } from '@angular/core';

@Component({
  selector: 'app-assignment-sportskills',
  templateUrl: './assignment-sportskills.component.html',
  styleUrl: './assignment-sportskills.component.scss'
})
export class AssignmentSportskillsComponent {

}
