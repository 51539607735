import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AssignmentCommunicationModel } from '../models/assignment-communication.model';
import { AssignmentChangeCommunicationResultModel } from '../models/assignment-change-communication-result.model';
import { AssignmentCommunicationNotesPostModel } from '../models/assignment-communication-note-post.model';
import { AssignmentCommunicationUpdatePostModel } from '../models/assignment-communication-update-post.model';
import { AssignmentCommunicationFeedbackPostModel } from '../models/assignment-communication-feedback-post.model';
@Injectable({

  providedIn: 'root'
})
export class AssignmentsCommunicationService {

  constructor(private httpClient: HttpClient) { }

  getAssignmentCommunication(assignmentId: number): Observable<AssignmentCommunicationModel> {
    return this.httpClient.get<AssignmentCommunicationModel>(`${environment.api}/assignments/${assignmentId}/communication/communicationDetails`);
  }

  sendAssignmentCommunicationNote(assignmentId: number, assignmentCommunicationNoteModel: AssignmentCommunicationNotesPostModel): Observable<AssignmentChangeCommunicationResultModel> {
    return this.httpClient.post<AssignmentChangeCommunicationResultModel>(`${environment.api}/assignments/${assignmentId}/communication/note`, assignmentCommunicationNoteModel)
  }

  sendAssignmentCommunicationUpdate(assignmentId: number, assignmentCommunicationUpdateModel: AssignmentCommunicationUpdatePostModel): Observable<AssignmentChangeCommunicationResultModel> {
    return this.httpClient.post<AssignmentChangeCommunicationResultModel>(`${environment.api}/assignments/${assignmentId}/communication/update`, assignmentCommunicationUpdateModel)
  }

  sendAssignmentCommunicationFeedback(assignmentId: number, assignmentCommunicationFeedbackModel: AssignmentCommunicationFeedbackPostModel): Observable<AssignmentChangeCommunicationResultModel> {
    return this.httpClient.post<AssignmentChangeCommunicationResultModel>(`${environment.api}/assignments/${assignmentId}/communication/feedback`, assignmentCommunicationFeedbackModel)
  }
}
