import { Component, Input, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../services/assignment-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { ExchangeAssignmentsModalComponent } from '../exchange-assignments-modal/exchange-assignments-modal.component';

@Component({
    templateUrl: './exchange-assignments.component.html',
    styleUrl: './exchange-assignments.component.scss',
})
export class ExchangeAssignmentsComponent implements OnInit {
    @Input() selectedAssignments?: AssignmentsOverviewModel[] = [];
    assignmentEventType = AssignmentEventType;
    modalRef?: BsModalRef;
    
    constructor(
        public assignmentsStateService: AssignmentStateService,
        private headerStateService: HeaderStateService,
        private bsModalService: BsModalService,
        public assignmentStateService: AssignmentStateService) {
        this.headerStateService.setDetailHeader(
            'Meine Einsätze abtauschen',
            'Tausche Einsätze mit anderen TLs',
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        this.assignmentsStateService.loadUpcomingAssignments();
        this.registerEvents();
    }

    public openSubstituteModal(): void {
        const initialState = { selectedAssignments: this.selectedAssignments };
        this.modalRef = this.bsModalService.show(ExchangeAssignmentsModalComponent, { initialState })
        this.modalRef?.content.onSuccess.subscribe((success: boolean) => {
            if (success) {
                this.assignmentStateService.loadUpcomingAssignments();
            }
        })
    }

    public onAssignmentSelected(assignments: AssignmentsOverviewModel[]): void {
        this.selectedAssignments = assignments;
    }

    public openFilterModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.exchangeAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    private registerEvents(): void {
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openFilterModal();
        })
    }
}
