import { Component, OnInit } from '@angular/core';
import { CourseDetailsStateService } from '../services/course-details-state.service';

@Component({
  selector: 'app-assignment-course-dates',
  templateUrl: './assignment-course-dates.component.html',
  styleUrl: './assignment-course-dates.component.scss'
})
export class AssignmentCourseDatesComponent implements OnInit {

  constructor(protected courseDetailsStateService: CourseDetailsStateService) { }

  mock = [
    { startDate: "00.00.00", endDate: null, startTime: "17:00", endTime: "18:00", location: "Poltyterasse, Dojo, 4. Stock, Kickflip, backflip" },
    { startDate: "12.09.24", endDate: null, startTime: "17:00", endTime: "18:00", location: "Poltyterasse, Dojo, 4. Stock, Kickflip, backflip" },
    { startDate: "00.00.00", endDate: "00.00.00", startTime: "00:00", endTime: "00:00", location: "Höngerberg" },
    { startDate: "12.12.24", endDate: null, startTime: "17:00", endTime: "18:00", location: "Winterthur" },
    { startDate: "07.10.24", endDate: "12.09.24", startTime: "17:00", endTime: "18:00", location: "Poltyterasse" }
  ];

  ngOnInit(): void {
  }

}
