import { Component } from '@angular/core';

@Component({
  selector: 'app-assignment-attendance',
  templateUrl: './assignment-attendance.component.html',
  styleUrl: './assignment-attendance.component.scss'
})
export class AssignmentAttendanceComponent {

}
