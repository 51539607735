import { Component, Input, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentStateService } from '../services/assignment-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { OpenAssignmentsModalComponent } from '../open-assignments-modal/open-assignments-modal.component';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';
import { BehaviorSubject } from 'rxjs';

@Component({
    templateUrl: './open-assignments.component.html',
    styleUrl: './open-assignments.component.scss',
})
export class OpenAssignmentsComponent implements OnInit {
    @Input() selectedAssignments?: AssignmentsOverviewModel[] = [];
    assignmentEventType = AssignmentEventType;
    modalRef?: BsModalRef;

    constructor(
        public assignmentsStateService: AssignmentStateService,
        private headerStateService: HeaderStateService,
        private bsModalService: BsModalService,
        public assignmentStateService: AssignmentStateService
    ) {
        this.headerStateService.setDetailHeader(
            'Offene Einsätze',
            'Siehe offene Einsätze',
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        this.assignmentsStateService.loadOpenAssignments();
        this.registerEvents();
    }

    public openTakeoverModal(): void {
        const initialState = { selectedAssignments: this.selectedAssignments };
        this.modalRef = this.bsModalService.show(OpenAssignmentsModalComponent, { initialState });
        this.modalRef?.content.onSuccess.subscribe((success: boolean) => {
            if (success) {
                this.assignmentStateService.loadOpenAssignments();
            }
        })
    }

    public onAssignmentSelected(assignments: AssignmentsOverviewModel[]): void {
        this.selectedAssignments = assignments;
    }

    public openFilterModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.openAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    private registerEvents(): void {
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openFilterModal();
        })
    }
}
