<div class="d-inline-flex border rounded-1 align-items-center max-w-300 p-2">
  <div class="d-flex flex-wrap overflow-hidden">
    <span
      class="filter-chip text-truncate me-1"
      style="max-width: 150px">
      {{visibleText}}
    </span>
  </div>
  <span
    class="filter-chip more-items ms-1 flex-shrink-0"
    *ngIf="overflowCount > 0">
    +{{ overflowCount }} more
  </span>
</div>