import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AssignmentsFilterModel } from '../models/assignments-filter.model';
import { TranslocoService } from '@jsverse/transloco';
import { AssignmentStateService } from '../services/assignment-state.service';

@Component({
  selector: 'app-filter-display',
  templateUrl: './filter-display.component.html',
  styleUrl: './filter-display.component.scss'
})
export class FilterDisplayComponent implements OnInit {
  @Input() filterModelSubject?: BehaviorSubject<AssignmentsFilterModel | null>;

  dateItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  searchTextItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  skillLevelItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  weekdaysItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  
  get hasItems(): boolean | null {
    return (
      (this.dateItems.value && this.dateItems.value.length > 0) ||
      (this.searchTextItems.value && this.searchTextItems.value.length > 0) ||
      (this.skillLevelItems.value && this.skillLevelItems.value.length > 0) ||
      (this.weekdaysItems.value && this.weekdaysItems.value.length > 0)
    );
  }

  constructor(
    private translocoService: TranslocoService,
    private assignmentStateService: AssignmentStateService
  ) { }

  ngOnInit(): void {
    if (this.filterModelSubject) {
      this.subscribeToFilterModelChanges();
    }
  }

  public resetFilterDisplayItems(): void {
    this.dateItems.next([]);
    this.searchTextItems.next([]);
    this.skillLevelItems.next([]);
    this.weekdaysItems.next([]);
    this.resetFilterModel();
  }

  private resetFilterModel(): void {
    const defaultFilterModel = this.assignmentStateService.setAssignmentFilterDefault();
    this.filterModelSubject?.next(defaultFilterModel);
  }

  private subscribeToFilterModelChanges(): void {
    this.filterModelSubject?.subscribe((filterModel) => {
      if (!filterModel) return;
      this.updateFilterDisplay(filterModel);
    });
  }

  private updateFilterDisplay(filterModel: AssignmentsFilterModel): void {
    if (filterModel.dateFrom || filterModel.dateTo) {
      let dateitems = [`${filterModel.dateFrom || ''} - ${filterModel.dateTo || ''}`.trim()];
      this.dateItems.next(dateitems);
    }

    if (filterModel.searchText?.trim()) {
      let searchTextItems = [`${filterModel.searchText}`];
      this.searchTextItems.next(searchTextItems)
    }

    if (filterModel.skillLevel?.trim()) {
      let skillLevelItems = [`${filterModel.skillLevel || ''}`];
      this.skillLevelItems.next(skillLevelItems)
    }

    if (filterModel.weekdays) {
      let weekdayItems: string[] = [];

      if (!filterModel.isWeekdayFilterActive) {
        this.weekdaysItems.next([this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.fullWeek')]);
        return;
      }

      if (filterModel.weekdays.isMondayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.monday'));
      if (filterModel.weekdays.isTuesdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.tuesday'));
      if (filterModel.weekdays.isWednesdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.wednesday'));
      if (filterModel.weekdays.isThursdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.thursday'));
      if (filterModel.weekdays.isFridayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.friday'));
      if (filterModel.weekdays.isSaturdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.saturday'));
      if (filterModel.weekdays.isSundayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.sunday'));

      if (weekdayItems.length === 7) {
        this.weekdaysItems.next([this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.fullWeek')]);
      } else {
        this.weekdaysItems.next(weekdayItems);
      }
    }
  }
}
