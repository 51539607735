import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsCommunicationStateService } from '../../services/assignments-communication-state-service';
import { AssignmentsCommunicationService } from '../../services/assignments-communication.service';
import { AssignmentCommunicationUpdatePostModel } from '../../models/assignment-communication-update-post.model';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-assignment-communication-update-modal',
  templateUrl: './assignment-communication-update-modal.component.html',
  styleUrl: './assignment-communication-update-modal.component.scss'
})
export class AssignmentCommunicationUpdateModalComponent {
  errorMessage: string | null = null;
  isLoading: boolean = true;
  assignmentId!: number;
  updateForm?: FormGroup;
  assignmentCommunicationUpdateModel!: AssignmentCommunicationUpdatePostModel;

  constructor(
    private assignmentCommunicationStateService: AssignmentsCommunicationStateService,
    private assignmentCommunicationService: AssignmentsCommunicationService,
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.assignmentCommunicationService.getAssignmentCommunication(this.assignmentId)
      .subscribe({
        next: (communicationModel) => {
          this.isLoading = false
          this.initForm(communicationModel.update);
        }
      });
  }

  public saveForm(): void {
    if (this.updateForm?.valid) {
      this.sendAssignmentCommunicationUpdate();
    }
  }

  private sendAssignmentCommunicationUpdate(): void {
    this.errorMessage = null;
    this.isLoading = true;

    this.assignmentCommunicationUpdateModel = { update: this.updateForm?.value.update };
    this.assignmentCommunicationService.sendAssignmentCommunicationUpdate(this.assignmentId, this.assignmentCommunicationUpdateModel)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isSuccess) {
            this.assignmentCommunicationStateService.loadAssignmentCommunication(this.assignmentId);
            this.bsModalService.hide();
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.errorMessage = this.translocoService.translate('assignments.communication.modalError');
        }
      });
  }

  private initForm(value: string): void {
    this.updateForm = this.formBuilder.group({
      update: [value]
    });
  }
}
