import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentCommunicationFeedbackPostModel } from '../../models/assignment-communication-feedback-post.model';
import { AssignmentsCommunicationService } from '../../services/assignments-communication.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-assignment-communication-feedback-modal',
  templateUrl: './assignment-communication-feedback-modal.component.html',
  styleUrl: './assignment-communication-feedback-modal.component.scss'
})
export class AssignmentCommunicationFeedbackModalComponent implements OnInit {
  assignmentId!: number;
  errorMessage: string | null = null;
  feedbackForm!: FormGroup;
  assignmentCommunicationFeedbackModel!: AssignmentCommunicationFeedbackPostModel;

  constructor(
    private assignmentCommunicationService: AssignmentsCommunicationService,
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  public saveForm(): void {
    if (this.feedbackForm.valid) {
      this.sendAssignmentCommunicationFeedback();
    }
  }

  private sendAssignmentCommunicationFeedback(): void {
    this.errorMessage = null;

    this.assignmentCommunicationFeedbackModel = { feedback: this.feedbackForm.value.feedback };
    this.assignmentCommunicationService.sendAssignmentCommunicationFeedback(this.assignmentId, this.assignmentCommunicationFeedbackModel)
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.bsModalService.hide();
          }
        },
        error: (error) => {
          this.errorMessage = this.translocoService.translate('assignments.communication.modalError');
        }
      });
  }

  private initForm(): void {
    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required]
    })
  }
}
