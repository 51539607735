import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../services/assignment-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';

@Component({
  templateUrl: './past-assignments.component.html',
  styleUrl: './past-assignments.component.scss',
})
export class PastAssignmentsComponent implements OnInit {
  assignmentEventType = AssignmentEventType;
  modalRef?: BsModalRef;

  constructor(
    public assignmentsStateService: AssignmentStateService,
    private headerStateService: HeaderStateService,
    private bsModalService: BsModalService
  ) {
    this.headerStateService.setDetailHeader(
      'Vergangene Einsätze',
      'Sehe deine letzten Einsätze',
      '/assignments',
      'fa-solid fa-filter text-primary'
    );
  }

  ngOnInit(): void {
    this.assignmentsStateService.loadPastAssignments();
    this.registerEvents();
  }

  // Opening the FilterModal
  public openFilterModal(): void {
    this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
    this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.pastAssignmentsFilterSubject;
    this.modalRef.content.loadSavedFilters();
  }

  // Opening FilterModal in the Header
  private registerEvents(): void {
    this.headerStateService.getActionClickedEvent().subscribe((x) => {
      this.openFilterModal();
    })
  }
}
