<pmt-action-modal
  [title]="'assignments.participants.contactParticipants' | transloco"
  [submitButtonText]="'modal.sendButton' | transloco"
  [submitButtonIconCode]="''"
  (submitted)="saveForm()"
>
  <form [formGroup]="participantForm">
    <textarea
      formControlName="message"
      class="form-control"
      rows="3"
    ></textarea>
  </form>
</pmt-action-modal>
