<div class="container d-flex flex-column gap-3">
  <ng-container *ngIf="hasItems">
    <button class="btn btn-primary" (click)="resetFilterDisplayItems()">{{"assignments.assignmentsFilterModal.clearButton" | transloco}}</button>
  </ng-container>
  <div class="d-inline-flex flex-wrap justify-content-start">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-5 col-xl-4">
        <div *ngIf="dateItems">
          <app-filter-display-item
            [items]="dateItems"
          ></app-filter-display-item>
        </div>
        <div *ngIf="searchTextItems">
          <app-filter-display-item
            [items]="searchTextItems"
          ></app-filter-display-item>
        </div>

        <div *ngIf="skillLevelItems">
          <app-filter-display-item
            [items]="skillLevelItems"
          ></app-filter-display-item>
        </div>

        <div *ngIf="weekdaysItems">
          <app-filter-display-item
            [items]="weekdaysItems"
          ></app-filter-display-item>
        </div>
      </div>
    </div>
  </div>
</div>
