<div class="form-group d-flex flex-column gap-1">
  <div class="d-flex flex-row gap-1">
    <div class="flex-grow-1">
      <label>{{ "assignments.communication.update" | transloco }}</label>
    </div>
    <div>
      <button class="btn btn-sm btn-primary" (click)="openModal()"><i class="fa-solid fa-pen"></i></button>
    </div>
  </div>
  <textarea disabled readonly rows="3"
    class="form-control readonly disabled">{{(assignmentCommunicationStateService.assignmentCommunicationSubject | async)?.update}}</textarea>
</div>