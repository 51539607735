import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssignmentParticipantContactingPostModel } from '../../models/assignment-participants-contacting.model';
import { AssignmentsParticipantService } from '../../services/assignments-participants.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-assignment-participants-contacting-modal',
  templateUrl: './assignment-participants-contacting-modal.component.html',
  styleUrl: './assignment-participants-contacting-modal.component.scss'
})
export class AssignmentContactParticipantsModalComponent implements OnInit {
  errorMessage: string | null = null;
  assignmentId!: number;
  participantForm!: FormGroup;
  assignmentContactingParticipantsModel!: AssignmentParticipantContactingPostModel

  constructor(
    private formBuilder: FormBuilder,
    private assignmentParticipantService: AssignmentsParticipantService,
    private bsModalService: BsModalService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public saveForm() {
    if (this.participantForm.valid) {
      this.sendMessageToParticipants();
    }
  }

  private sendMessageToParticipants(): void {
    this.errorMessage = null;

    this.assignmentContactingParticipantsModel = { message: this.participantForm.value.message };
    this.assignmentParticipantService.sendMessageToAssignmentParticipants(this.assignmentId, this.assignmentContactingParticipantsModel)
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.bsModalService.hide();
          }
        },
        error: (error) => {
          this.errorMessage = this.translocoService.translate('assignments.communication.modalError');
        }
      });
  }

  private initForm(): void {
    this.participantForm = this.formBuilder.group({
      message: ['', Validators.required]
    })
  }

}
