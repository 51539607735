import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CourseDetailsModel } from "../models/course-details.model";
import { CourseDetailsService } from "./course-details.service";

@Injectable({
    providedIn: 'root'
})

export class CourseDetailsStateService {
    public courseDetailsSubject = new BehaviorSubject<CourseDetailsModel | null>(null);

    constructor(private CourseDetailsService: CourseDetailsService) { }

    public loadCourseDetails(assignmentId: number): void {
        console.log('Loading data for Id: {id}', assignmentId);

        this.CourseDetailsService.getCourseDetails(assignmentId).subscribe({
            next: courseDetails => {
                this.courseDetailsSubject.next(courseDetails);
            },
            error: (error) => {
            }
        })
    }
}
