import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';

@Component({
    templateUrl: './assignments-page.component.html',
    styleUrl: './assignments-page.component.scss',
})
export class AssignmentsPageComponent {
    constructor(private headerStateService: HeaderStateService) {
        this.headerStateService.setDetailHeader(
            'Einsätze',
            'Details zu deinen Einsätzen findest du hier'
        );
    }
}
