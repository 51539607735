import { Component, Input } from '@angular/core';
import { AssignmentsOverviewModel } from '../../models/assignments-overview.model';
import { CourseDetailsStateService } from '../services/course-details-state.service';

@Component({
  selector: 'app-assignment-information',
  templateUrl: './assignment-information.component.html',
  styleUrl: './assignment-information.component.scss'
})
export class AssignmentInformationComponent {
  @Input() assignmentId!: number;
  assignmentOverviewModel!: AssignmentsOverviewModel | null; // Make information model because date and Location Room are a list

  constructor(protected CourseDetailsStateService: CourseDetailsStateService) { }

}
