import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseDetailsModel } from '../models/course-details.model';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CourseDetailsService {

    constructor(private httpClient: HttpClient) { }

    getCourseDetails(assignmentId: number): Observable<CourseDetailsModel> {
        return this.httpClient.get<CourseDetailsModel>(`${environment.api}/assignments/${assignmentId}`);
    }
}
